<template>
    <div
        class="asset-scheme-view"
        :class="{
            'asset-scheme-view--scaled': !isOriginalSize,
        }"
    >
        <div v-if="isLoading">
            <v-spinner
                class="l-padded"
                size="medium"
                line-fg-color="#000"
                :speed="1"
            />
        </div>

        <template v-else>
            <div class="l-padded">
                <label class="l-inline l-gap-1">
                    <input v-model="isOriginalSize" type="checkbox" />

                    <span>{{ $t('displayInOriginalSize') }}</span>
                </label>
            </div>

            <div ref="markup" v-html="markup" />
        </template>
    </div>
</template>

<script>
import VSpinner from 'vue-simple-spinner'
import { Base64 } from 'js-base64'
import { get } from 'lodash'

import { httpHelper } from '@/utils'

const wildcardPrefix = '$.'

const replaceValueById = (el, data) => {
    el.children.forEach(child => {
        if (child.id.startsWith(wildcardPrefix)) {
            const valuePath = child.id.replace(wildcardPrefix, '')
            child.innerHTML = get(data, valuePath)
        } else if (child.children) {
            replaceValueById(child, data)
        }
    })
}

export default {
    name: 'AssetSchemeView',
    components: {
        VSpinner,
    },
    data() {
        return {
            isLoading: true,
            isOriginalSize: false,
            markup: null,
        }
    },
    computed: {
        entry() {
            return this.$parent.entryDetailed
        },
    },
    watch: {
        ['$parent.entryDetailed']: {
            immediate: true,
            async handler() {
                const url = this.entry?.asset_details.schema_url
                if (!url) {
                    return
                }

                this.isLoading = false
                const { data } = await httpHelper.get(url)
                this.markup = Base64.decode(data.svg)
                this.isLoading = false
                this.$nextTick(() => {
                    replaceValueById(this.$refs.markup, this.entry)
                })
            },
        },
    },
    sockets: {
        sensor_data(data) {
            if (this.isLoading || data.id !== this.entry.id) {
                return
            }

            Object.entries(data.values).forEach(([key, value]) => {
                const id = `$.asset_details.sensor_data.${key}.value`
                const svgEl = this.$refs.markup.firstElementChild
                const targetEl = svgEl.getElementById(id)
                if (targetEl) {
                    targetEl.innerHTML = value
                }
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "displayInOriginalSize": "Display in original size"
    },
    "de": {
        "displayInOriginalSize": "In Originalgrösse anzeigen"
    },
    "it": {
        "displayInOriginalSize": "Visualizzazione in formato originale"
    }
}
</i18n>

<style lang="scss">
.asset-scheme-view {
    & > div {
        &:last-child {
            padding: 0;
            overflow: auto;
        }
    }

    &--scaled {
        & > div {
            &:last-child {
                padding: 0 1rem 1rem;
            }
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    @include respond-to('for-tablet-down') {
        & > div {
            &:last-child {
                max-height: 100%;
            }
        }
    }
}
</style>
